.number-pad-container {
  @apply flex flex-col items-center bg-white max-w-md mx-auto w-full;
}

.result-window {
  @apply text-left flex-grow w-full mb-[13px] px-[26px] py-[10px] text-grayDark3 bg-grayLight2 rounded-[50px]; /* Full width */
  font-size: 15px;
  line-height: 24px;
  position: relative;
}

.number-pad {
  @apply grid grid-cols-3 gap-5 w-full pt-3 pb-14 bg-grayLight2;
}

.number-pad-button {
  @apply flex items-center justify-center text-lg bg-grayLight2 outline-none; /* No outline */
  font-size: 25px;
  line-height: normal;
  letter-spacing: 0.291px;
}

.delete-button {
  @apply flex items-center justify-center p-4 text-lg outline-none; /* No outline */
}

.prefix {
  @apply text-grayDark3 font-medium;
}

.suffix {
  @apply text-grayDark3 font-medium;
}

.value {
  @apply text-black font-medium w-2;
}

.confirm-button {
  @apply absolute right-4 p-0 pr-2;
}

.confirm-icon {
  @apply h-6 w-6;
}
