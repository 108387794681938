/* Overlay for the background */
.overlay {
  @apply fixed inset-0 flex items-end z-50; /* Align content to the bottom */
  z-index: 50; /* Ensure the overlay is behind the content-container */
  background-color: (138, 134, 133, 0.1);
}

/* Content container for the main section (90% height) */
.content-container {
  @apply w-full max-w-md h-full bg-white rounded-t-2xl shadow-lg mt-4 py-[20px] px-[13px];
  z-index: 50; /* Ensure the content-container is on top of the overlay */
  position: relative;
  overflow-y: hidden;
}

.header-container {
  @apply flex flex-row items-center justify-between border-b border-gray pb-4 bg-white w-full p-4;
  position: sticky;
  top: 0; /* Stick to the top of the content container */
  z-index: 101; /* Ensure it's on top of other content */
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: white; /* Ensure the background is solid */
}

/* Small overlay for modals */
.small-overlay {
  @apply fixed inset-0 bg-gray bg-opacity-50 flex items-end justify-center;
  z-index: 150; /* Above the main content */
}

/* Modal content for smaller popups with scrollable content and two columns */
.modal-content {
  @apply w-full max-w-md h-[30%] bg-white rounded-t-2xl shadow-lg p-6 overflow-y-auto;
  z-index: 200; /* Ensure it is above the overlay */
}

.modal-content h2 {
  @apply text-lg font-semibold mb-4;
}

/* Grid layout for two columns in the modal */
.modal-grid {
  @apply grid grid-cols-2 gap-4;
}

.header-title {
  @apply flex flex-col items-center justify-center;
}

.title {
  @apply text-2xl font-bold;
}

.date-info {
  @apply text-xs text-gray;
}

.sort-container {
  @apply flex flex-row items-center justify-between mb-4 mt-4 bg-white;
  position: sticky;
  top: 60px; /* Adjust this based on the height of your header-container */
  z-index: 100; /* Ensure it's above the scrollable content */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.sort-button {
  @apply text-sm text-gray shadow-md rounded-full p-2 px-4;
}

.apartmentlist-container {
  max-width: 100vw; /* Limits the width to the viewport */
  height: calc(100% - 96px);
  box-sizing: border-box;
  overflow-y: auto; /* Vertical scroll only when necessary */
  overflow-x: hidden; /* Hides horizontal scroll */
}

.apartment-confirm-container {
  @apply absolute left-1/2 transform -translate-x-1/2 w-full bottom-0 px-4 pb-6 z-50; /* Fixed positioning and center horizontally */
  display: flex;
  justify-content: center; /* Center the button */
}
