/* Container for the car list */
.apartment-list {
  @apply p-4 bg-white rounded-[20px];
}

/* Each car item */
.apartment-item {
  @apply flex items-center justify-between border-grayMid2 mb-4 pb-4;
  border-bottom-width: 0.5px;
}

/* Number badge (circle on the left) */
.apartment-item-number {
  @apply font-bold rounded-full flex items-center justify-center w-6 h-6 text-[12px] p-[10px];
}

/* Content wrapper for car image and details */
.apartment-item-content {
  @apply flex items-center flex-grow;
}

.header {
  @apply font-bold text-lg flex justify-center items-center;
}
/* Car details (brand, name, price) */
.apartment-details {
  @apply flex flex-col overflow-hidden pl-2;
}

.apartment-location {
  @apply text-[12px] text-grayMid;
}
/* Car name text */
.apartment-name {
  @apply text-sm font-medium;
}

/* Car price text */
.apartment-price {
  @apply text-sm text-nowrap;
}
