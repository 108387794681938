/* Overlay for the background */
.chatbot-overlay {
  @apply inset-0 flex items-end z-50;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
}

.bg-custom {
  background-color: rgba(138, 134, 133, 0.1);
}

.header {
  @apply relative flex justify-center items-center pt-4 py-[10px] px-[13px] text-lg text-orange uppercase;
}

.header-button {
  @apply h-6 w-6;
  position: absolute;
  left: 13px;
}

.message-window {
  @apply flex-1 overflow-y-auto p-6 px-[13px];
}
.input-container {
  @apply flex-none p-4;
}

.chatbot-window {
  @apply flex flex-col h-[100dvh] w-[100dvw] max-w-md;
}
