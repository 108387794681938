.w-100 {
  @apply w-full;
}

a {
  text-decoration: none;
  text-align: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  height: 100dvh;
  overflow: auto;
}

.max-w-540 {
  @apply max-w-md;
}

.btn-wrapper {
  padding: 0 24px;
}

.btn {
  padding: 8px 0px;
  border: none;
  border-radius: 8px;

  color: #a9a8a7;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 160% */
  cursor: pointer;
}

.btn.primary {
  background-color: #3282f6;
  color: #f9fcff;
}

.btn.finberry {
  background-color: #ff6319;
  color: #fff;
}

.text-center {
  text-align: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.confirm-loading {
  margin-top: 72px;
  height: 392px;
  justify-content: space-between;
}

.confirm-success {
  display: none;
  margin-top: 72px;
}

.button-group {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.payment-title {
  margin-top: 32px;
  margin-bottom: 0;
  color: #2b160c;
  font-weight: 600;
  font-size: 20px;
  font-style: normal;
}

.description {
  margin-top: 8px;
  color: #4e5968;
  font-size: 17px;
  font-weight: 500;
}

.response-section {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 20px;
}

.response-section .response-label {
  color: #8a8685;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 21px */
  letter-spacing: -0.3px;
}

.response-section .response-text {
  color: #8a8685;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 21px */
  letter-spacing: -0.3px;
  padding-left: 16px;
  word-break: break-word;
  text-align: right;
}

.color-grey {
  color: #b0b8c1;
}
