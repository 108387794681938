/* Round checkbox styles */
.round-checkbox {
  @apply flex items-center h-auto mb-0 text-[15px] leading-6 cursor-default;
}

.round-checkbox-input {
  @apply appearance-none w-6 h-6 mr-4 rounded-full cursor-pointer;
  background-image: url('/public/picture/icons/round-checkbox.png');
  background-size: 100% 100%; /* Scale background to cover the area completely */
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.round-checkbox-input:checked {
  @apply border-orange;
  background-image: url('/public/picture/icons/round-checkbox-checked.png');
  background-size: 100% 100%; /* Scale background to cover the area completely */
  background-position: center;
  background-repeat: no-repeat;
  border: 1px;
}

/* Inner checkmark styling for the checked state */
.round-checkbox-input:checked::before {
  @apply w-3 h-3; /* Inner checkmark circle */
  content: ''; /* Required for pseudo-element */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkbox-input {
  @apply appearance-none w-5 h-5 mr-2 cursor-pointer;
  background-image: url('/public/picture/icons/checkbox.png');
  background-size: 100% 100%; /* Scale background to cover the area completely */
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.checkbox-input:checked {
  @apply border-orange;
  background-image: url('/public/picture/icons/checkbox-checked.png');
  background-size: 100% 100%; /* Scale background to cover the area completely */
  background-position: center;
  background-repeat: no-repeat;
  border: 1px;
}
