@layer base {
  body {
    height: 100dvh; /* 화면 높이에 맞게 적용 */
    margin: 0; /* 여백 제거 */
  }
  #root {
    @apply w-full min-h-[100dvh] min-w-[320px] flex justify-center text-black bg-white;
    color-scheme: light only;
    forced-color-adjust: none; /* 강제 색상 모드 방지 */
  }

  @media (prefers-color-scheme: dark) {
    html,
    body {
      --tw-bg-opacity: 1 !important;
      --tw-text-opacity: 1 !important;
      background-color: transparent !important;
      color: inherit !important;
      filter: invert(100%);
    }
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
