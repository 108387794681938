/* Car item layout */
.apartment-list-item {
  @apply flex items-center justify-between mb-2 py-[9px] px-5;
  border-radius: 20px;
  box-shadow: 0px 12px 20px -12px rgba(196, 196, 196, 0.25);
}

.apartment-item-number {
  @apply rounded-full w-[24px] h-[24px] flex items-center justify-center text-sm font-bold p-[10px];
}

.apartment-item-content {
  @apply flex items-center ml-4 mr-4;
}

.apartment-details p {
  @apply text-left;
}

.apartment-container {
  @apply w-full;
}
