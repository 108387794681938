.header {
  @apply relative flex justify-center items-center pt-[60px] py-[10px] px-[13px] text-orange uppercase;
}

.header-button {
  @apply h-6 w-6;
  position: absolute;
  left: 13px;
}

.header-container {
  @apply flex items-center justify-between pt-5 py-[10px] px-[13px] text-lg text-orange uppercase;
  position: sticky;
  top: 0; /* Stick to the top of the content container */
  z-index: 50; /* Ensure it's on top of other content */
  padding-bottom: 4px;
  border: none;
  background-color: white; /* Ensure the background is solid */
}

.close-header-container {
  @apply flex items-center justify-center pt-5 py-[10px] text-lg text-orange uppercase;
  position: sticky;
  top: 0; /* Stick to the top of the content container */
  z-index: 50; /* Ensure it's on top of other content */
  padding-bottom: 4px;
  border: none;
  background-color: white; /* Ensure the background is solid */
}

.report-header-container {
  @apply absolute flex items-center justify-center w-full py-[14.5px] uppercase;
  z-index: 20;
  border-bottom: 1px solid #ebebeb;
  background-color: inherit;
}

.header-left-button {
  @apply flex items-center justify-between text-base;
}

.header-title {
  @apply flex flex-col items-center justify-center;
}

.title {
  @apply text-lg font-bold;
}

.date-info {
  @apply font-normal text-[10px] text-black;
}

.close-button {
  @apply h-6 w-6;
  position: absolute;
  right: 13px;
}

.close-text {
  @apply pr-[10px] pl-[10px];
  position: absolute;
  right: 16px;
  display: flex;
}
