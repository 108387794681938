/* Chat Input */
.chat-input {
  @apply flex-1 p-2 border rounded shadow;
  border-color: var(--text-color);
}

/* Assistant message layout */
.assistant-message {
  @apply relative text-left mb-1.5 rounded-[0px_24px_24px_24px] px-5 py-3 bg-orangeLight4 max-w-[274px] mr-auto whitespace-pre-line;
  width: fit-content;
}

.carnamecard-message {
  @apply relative text-left mb-1.5 rounded-[0px_24px_24px_24px] px-[13px] py-[13px] bg-orangeLight4 max-w-[274px] mr-auto;
}

.apartmentnamecard-message {
  @apply relative text-left mb-1.5 rounded-[0px_24px_24px_24px] px-5 py-3 bg-orangeLight4 max-w-[274px] mr-auto;
}

/* User message layout */
.user-message {
  @apply text-right mb-2 rounded-[25px_0px_25px_25px] px-5 py-3 text-white bg-orange max-w-[274px] ml-auto;
  width: fit-content;
}

/* Horizontal options container */
.options-container-horizontal {
  @apply mt-1.5 flex flex-row flex-wrap pb-[20px] items-center text-center max-w-[274px];
}

/* Vertical options container */
.options-container-vertical {
  @apply mt-1.5 pb-[20px] items-center text-center;
  position: relative; /* Relative positioning to keep subtext aligned properly */
}

/* Option button layout */
.option-button {
  @apply block w-auto p-1 pl-4 pr-4 border rounded-full mb-1 mr-1 text-orange;
  display: flex; /* Use flexbox to align text */
  justify-content: center; /* Horizontally center the text */
  align-items: center; /* Vertically center the text */
  height: 32px; /* Set a fixed height to avoid resizing */
  text-align: center; /* Ensure the text is centered */
  font-size: 15px;
}

/* Subtext container layout */
.sub-text-container {
  @apply border border-orange rounded-lg p-2 bg-grayLight2;
  position: absolute; /* Float it above other elements */
  top: -100%; /* Align it vertically to the top of the button */
  left: 50%; /* Place it halfway to the right of the button */
  transform: translate(0%, -12%);
  z-index: 10; /* Ensure it overlaps other elements */
  max-width: 300px; /* Limit the width to prevent overflow */
  white-space: normal; /* Ensure text doesn't overflow */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Add some shadow for better visibility */
}
.sub-text-container::before {
  content: '';
  position: absolute;
  top: calc(50% - 10px); /* Vertically center the arrow */
  left: -10px; /* Place it to the left side */
  width: 0;
  height: 0;
  border-top: 10px solid transparent; /* Top side of the triangle */
  border-bottom: 10px solid transparent; /* Bottom side of the triangle */
  border-right: 10px solid orange; /* Right side of the triangle (pointing left) */
  z-index: 10; /* Ensure it's on top */
}
/* Subtext visibility control */
.visible {
  display: block;
}

.hidden {
  display: none;
}

.self-end {
  margin-left: 0;
}

.finberry-profile {
  position: absolute;
  width: 36px;
  height: 36px;
  object-fit: contain;
}

/* Finberry tag */
.finberry-tag {
  @apply text-base font-bold leading-[14px] tracking-[-0.14px] uppercase text-orange;
  margin-left: 45px;
  font-size: 14px;
}
