.overlay {
  @apply fixed inset-0 flex justify-center items-end z-50; /* Align content to the bottom */
  z-index: 50; /* Ensure the overlay is behind the content-container */
  background-color: (138, 134, 133, 0.1);
}

.calculator-confirm-container {
  @apply absolute w-full bottom-20 px-4 pb-4 z-50; /* Fixed positioning and center horizontally */
  display: flex;
  justify-content: center; /* Center the button */
}

.calculator-confirm-button {
  @apply bg-orange text-white text-sm font-semibold py-[13px] px-[10px] rounded-[5px] block w-full;
}

.calculator-header-container {
  @apply flex flex-row items-center justify-between border-b border-gray bg-white text-orange;
  position: sticky;
  top: 0; /* Stick to the top of the content container */
  z-index: 50; /* Ensure it's on top of other content */
  padding-left: 0;
  padding-right: 0.5rem;
  padding-bottom: 0;
  border: none;
  background-color: white; /* Ensure the background is solid */
}

.loan-calculator-container {
  height: calc(100% - 72px);
}
