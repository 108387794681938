.sort-options-container {
  @apply absolute top-16 right-4 p-4 rounded-lg shadow-lg bg-white;
  z-index: 200;
}

.sort-options-container h3 {
  @apply text-lg font-semibold mb-4;
}

.radio-container {
  @apply flex flex-col space-y-2;
}

.radio-button {
  @apply flex items-center text-[15px] leading-6 mr-4;
}

/* Base style for hidden input with custom appearance */
.radio-button-input {
  @apply w-6 h-6 mr-4 rounded-full border-2 border-grayMid2 cursor-pointer;
  appearance: none; /* Removes default radio button styling */
  position: relative; /* Allows for absolute positioning of inner dot */
  outline: none; /* Removes default blue outline */
}

/* When the radio button is checked */
.radio-button-input:checked {
  @apply border-orange; /* Orange border for selected state */
}

/* Adding the inner circle (dot) */
.radio-button-input:checked::before {
  content: ''; /* Needed to display the pseudo-element */
  @apply w-3 h-3 bg-orange rounded-full; /* Inner orange dot */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the dot */
}

/* Removes focus outline when the radio button is focused */
.radio-button-input:focus {
  outline: none;
  box-shadow: none;
}
