/* Container for the car list */
.car-list {
  @apply p-4 bg-white rounded-[20px];
}

/* Each car item */
.car-item {
  @apply flex items-center justify-between border-grayMid2 mb-4 pb-4;
  border-bottom-width: 0.5px;
}

/* Number badge (circle on the left) */
.car-item-number {
  @apply font-bold rounded-full flex items-center justify-center w-[20px] h-[20px] text-[12px];
}

/* Content wrapper for car image and details */
.car-item-content {
  @apply flex items-center flex-grow m-0;
}

/* Car image */
.car-image {
  @apply w-[76px] h-[38px] ml-[15px];
}

.header {
  @apply relative font-bold text-lg flex justify-center items-center;
}

/* Car details (brand, name, price) */
.car-details {
  @apply flex flex-col overflow-hidden;
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  overflow: hidden; /* Ensures the text that overflows is clipped */
  text-overflow: ellipsis; /* Adds the ellipsis when the text is too long */
}

/* Car brand text */
.car-brand {
  @apply text-[12px] text-grayMid;
}

/* Car name text */
.car-name {
  @apply text-sm;
}

/* Car price text */
.car-price {
  @apply text-sm text-nowrap;
}

/* Button for viewing details */
.view-details-button {
  @apply block w-full bg-orange text-white font-bold rounded-[5px] text-center p-3;
}
