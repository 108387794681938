.report-background {
  @apply w-full max-w-md flex h-[100dvh] flex-col items-center justify-center overflow-y-auto;
  background: linear-gradient(180deg, #ff6319 62.69%, #ffa830 100%);
}

.report-container {
  @apply relative w-full max-w-md flex flex-col pt-11 text-[#484644] overflow-y-auto;
  height: calc(100dvh - 94px);
}

.popup-report-container {
  @apply fixed inset-0 left-1/2 transform -translate-x-1/2 w-full max-w-md bg-white z-50 text-[#484644] overflow-y-auto;
  height: 100dvh;
}

.report-shadow {
  @apply border border-[#f8f8f8] shadow-[0px_1px_4px_0px_rgba(12,12,13,0.05)];
}

.swiper-container div.swiper-pagination.swiper-pagination-progressbar {
  top: inherit;
  bottom: 76px;
  left: 24px;
  width: calc(100% - 48px);
  border-radius: 5px;
}

.swiper-container span.swiper-pagination-progressbar-fill {
  background-color: #ff6319;
  border-radius: 5px;
}

.swiper-container span.swiper-pagination-progressbar-fill.loading-progress {
  background-color: #fff;
  border-radius: 5px;
}

.swiper-container .swiper-pagination-progressbar {
  background-color: #f8f8f8;
  border-radius: 5px;
}

.swiper-container .swiper-pagination-progressbar.loading-progress {
  background-color: #ffbe8e;
  border-radius: 5px;
}

.report-paging {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-next-button {
  position: absolute;
  bottom: 40px;
  right: 24px;
  transform: translateY(15%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}

.custom-prev-button {
  position: absolute;
  bottom: 40px;
  left: 24px;
  transform: translateY(15%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}

.bg-label-gradient {
  background: linear-gradient(348deg, #ff900c 1.07%, #ff6319 96.79%);
}

.bg-progress-gradient {
  background: linear-gradient(270deg, #ff6319 0%, #ff900c 100%);
}

.bg-bar-gradient {
  background: linear-gradient(0deg, #ff900c 0%, #ff6319 100%);
}

.bg-progress-purple-gradient {
  background: linear-gradient(270deg, #aa7eb2 0%, #793b8a 100%);
}

.tab-list {
  @apply w-full h-9 bg-[#f7f7f7] rounded-lg justify-start items-center inline-flex;
}

.tab-list-item {
  @apply w-full h-9 py-1 outline-none border-2 border-[#f7f7f7] rounded-lg text-sm font-normal leading-[100%] tracking-[-0.2px] text-grayDark3 data-[selected]:font-semibold data-[selected]:bg-white data-[selected]:text-[#484644];
}

.tooltip {
  @apply inline-block relative text-white px-2 py-1 rounded text-xs font-bold text-center leading-[140%];
}

.tooltip::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ff7f32;
}

.tooltip.end::before {
  left: 90%; /* 삼각형 위치를 오른쪽으로 이동 */
  transform: translateX(-90%);
}

.report-container ul {
  list-style: initial;
  margin-left: 20px;
}

.popup-report-container ul {
  list-style: initial;
  margin-left: 20px;
}
