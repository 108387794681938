.image-swiper-container {
  height: 536px;
  padding: 52px;
}

.swiper-slide-custom {
  width: 200px; /* 슬라이드의 기본 크기 */
  height: 432px;
  transition:
    transform 0.2s ease,
    opacity 0.2s ease;
  opacity: 0.5; /* 비활성 슬라이드 투명도 */
}

.swiper-slide-custom img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 이미지가 비율에 맞춰 채워지도록 설정 */
}

.swiper-slide-custom.swiper-slide-active {
  transform: scale3d(1.25, 1.25, 1);
  -webkit-transform: scale3d(1.25, 1.25, 1); /* Safari 호환성 */
  opacity: 1; /* 활성 슬라이드 불투명 */
  will-change: transform; /* GPU에 미리 로드 */
  backface-visibility: hidden; /* 백페이스 렌더링 방지 */
  perspective: 1000px; /* 3D 렌더링 */
}

/* 커스텀 화살표 */
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 40px; /* 버튼 크기 */
  height: 40px; /* 버튼 크기 */
  background: #fff; /* 버튼 배경색 하얀색 */
  border-radius: 50%; /* 원형 버튼 */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 버튼에 그림자 추가 */
  cursor: pointer;
  border: none; /* 테두리 제거 */
}

.custom-button-prev {
  left: 20px; /* 왼쪽 화살표 위치 */
}

.custom-button-next {
  right: 20px; /* 오른쪽 화살표 위치 */
}

/* SVG 아이콘 */
.custom-arrow svg {
  width: 24px; /* SVG 크기 */
  height: 24px;
}

/* SVG 아이콘 경로 스타일 */
.custom-arrow svg path {
  stroke: #502660; /* 보라색 화살표 */
  stroke-width: 2; /* 보라색 화살표 두께 증가 */
}

.custom-arrow:hover svg path {
  transition: stroke 0.3s ease;
}

div.swiper-pagination {
  display: none;
}
