.text-input-container {
  @apply relative flex items-center p-[13px] pt-6 pb-11 w-full;
}
.multiple-answer-option-container {
  @apply flex flex-wrap;
}
.multiple-answer-option {
  @apply flex items-center m-1 p-2;
}
.multiple-answer-option-button {
  @apply ml-2 text-black;
}

.text-input {
  @apply relative flex-grow px-[26px] py-[10px] text-grayMid bg-grayLight2 rounded-[50px];
  font-size: 15px;
  line-height: 24px;
}
.text-input-button {
  @apply absolute right-4 p-2;
}
.text-input-button-icon {
  @apply h-6 w-6;
}
