/* Car item layout */
.car-list-item {
  @apply flex items-center justify-between mb-2 py-[9px] px-5 rounded-[20px];
  box-shadow: 0px 12px 20px -12px rgba(196, 196, 196, 0.25);
}

.car-item-number {
  @apply rounded-full w-[24px] h-[24px] flex items-center justify-center text-sm font-bold p-[10px];
}

.car-item-content {
  @apply flex items-center ml-4 mr-4;
}

.car-image {
  @apply w-16 h-16 mr-4 rounded-md;
}

.car-details {
  @apply text-left w-1/2;
}

.car-details p {
  @apply text-sm;
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  overflow: hidden; /* Ensures the text that overflows is clipped */
  text-overflow: ellipsis; /* Adds the ellipsis when the text is too long */
}

.car-container {
  @apply w-full;
}
