/* Overlay for the background */
.overlay {
  @apply fixed inset-0 flex justify-center items-end z-50; /* Align content to the bottom */
  z-index: 50; /* Ensure the overlay is behind the content-container */
  background-color: (138, 134, 133, 0.1);
}

/* Content container for the main section (90% height) */
.content-container {
  @apply w-full max-w-md h-full bg-white rounded-t-2xl shadow-lg py-[20px] px-[13px];
  z-index: 50; /* Ensure the content-container is on top of the overlay */
  position: relative;
  overflow-y: hidden;
}

/* Small overlay for modals */
.small-overlay {
  @apply fixed inset-0 flex items-end justify-center;
  z-index: 150; /* Above the main content */
  background-color: (138, 134, 133, 0.1);
}

.modal-wrapper {
  @apply w-full max-w-md h-[48%] max-h-[480px] pb-16 bg-white rounded-t-2xl shadow-lg;
}

.modal-wrapper h2 {
  @apply text-base font-semibold py-6 pl-8;
}

/* Modal content for smaller popups with scrollable content and two columns */
.modal-content {
  @apply w-full max-w-md h-[68%] px-8 py-0 overflow-y-auto;
  z-index: 200; /* Ensure it is above the overlay */
  box-shadow: none;
}

/* Grid layout for two columns in the modal */
.modal-grid {
  @apply grid grid-cols-2 gap-4;
}

/* Header with buttons and title */
.header-container {
  @apply flex flex-row items-center justify-between border-b border-gray bg-white text-orange;
  position: sticky;
  top: 0; /* Stick to the top of the content container */
  z-index: 50; /* Ensure it's on top of other content */
  padding-left: 0;
  padding-right: 0.5rem;
  padding-bottom: 0;
  border: none;
  background-color: white; /* Ensure the background is solid */
}

.header-button {
  @apply flex;
}

.title {
  @apply text-lg font-bold;
}

.date-info {
  @apply font-normal text-[10px] text-black;
}

.sort-container {
  @apply flex flex-row items-center justify-between mt-0 mb-4;
  position: sticky;
  top: 60px; /* Adjust this based on the height of your header-container */
  z-index: 50; /* Ensure it's above the scrollable content */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: none;
}

.sort-button {
  @apply rounded-full p-2 px-4 text-[15px] text-black leading-5;
}

.carlist-container {
  max-width: 100vw; /* Limits the width to the viewport */
  height: calc(100% - 132px);
  box-sizing: border-box;
  overflow-y: auto; /* Vertical scroll only when necessary */
  overflow-x: hidden; /* Hides horizontal scroll */
}

/* Custom checkbox styles */
.custom-checkbox {
  @apply flex items-center h-8 mb-2 text-[15px] leading-6 cursor-pointer;
}

.custom-checkbox-input {
  @apply appearance-none w-5 h-5 mr-4 bg-grayMid2 border border-grayMid rounded-[5px];
  position: relative;
  background-color: transparent; /* Ensures no underlying white background */
  background-size: cover; /* Ensures the background fills the element */
}

.custom-checkbox-input:checked {
  @apply border-orange;
  background-image: url('/public/picture/icons/checkbox-checked.png');
  background-size: 100% 100%; /* Scale background to cover the area completely */
  background-position: center;
  background-repeat: no-repeat;
  border: 1px;
}

/* Inner checkmark styling for the checked state */
.custom-checkbox-input:checked::before {
  @apply w-3 h-3; /* Inner checkmark circle */
  content: ''; /* Required for pseudo-element */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.car-confirm-container {
  @apply absolute left-1/2 transform -translate-x-1/2  w-full bottom-0 px-4 pb-4 z-50; /* Fixed positioning and center horizontally */
  display: flex;
  justify-content: center; /* Center the button */
}

.car-confirm-button {
  @apply bg-orange text-white text-sm font-semibold py-[13px] px-[10px] rounded-[5px] block w-full;
}
